import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {setActiveFiltersSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";

const ShopFilter = ({location, currentQuery, setCurrentQuery, filters, getSortParams}) => {
    const [hide, setHide] = useState(false)
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [display, setDisplay] = useState('none')

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left-filter button"
    );
    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    if (query && query.filters) {
        var element = document.getElementById(query.filters);
        if (element) {
            element.classList.add("active")
        }
    } else if (query && !query.filters){

    }

    window.addEventListener("resize", function () {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

    useEffect(() => {
        if (hide) {
            setDisplay('none')
        } else {
            setDisplay('block')
        }
    }, [hide])

    useEffect(() => {
        if (isMobileOnly || isSmallScreen) {
            setHide(false)
        }
    }, [isSmallScreen])

    return (
        <div className="sidebar-widget mt-10">
            <div>
                <h4 className="pro-sidebar-title">Trier par &nbsp;</h4>
                <div className="sidebar-widget-list mt-10"
                     style={{display: display, maxHeight: '200px', overflow: 'auto'}}>
                    {filters ? (
                        <ul>
                            {filters.map((filter, key) => {
                                return (
                                    <li key={key}>
                                        <div className="sidebar-widget-list-left-filter">
                                            <button
                                                id={filter}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("filters", "");
                                                        setCurrentQuery({...currentQuery, filters: null});
                                                    } else {
                                                        getSortParams("filters", filter);
                                                        setCurrentQuery({...currentQuery, filters: filter});
                                                    }
                                                    setActiveFiltersSort(e);
                                                }}
                                            >
                                                <span className="checkmark"/> {filter}{" "}
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        "No filters found"
                    )}
                </div>
            </div>
        </div>
    );
};

ShopFilter.propTypes = {
    filters: PropTypes.array,
    getSortParams: PropTypes.func,
    currentQuery: PropTypes.object,
    setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopFilter;
