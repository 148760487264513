import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";

import {slugify} from "../../helpers/product"

const ProductGridSingleTwo = ({
                                product,
                                currency,
                                sliderClassName,
                                spaceBottomClass,
                                colorClass,
                                titlePriceClass, isSide, index
                              }) => {

  const finalProductPrice = product.price;
  const discountedPrice = product.bestPrice;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = product.amount

  return (
      <Fragment>
        <div
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`product-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="product-img" style={{backgroundColor: "#f4f6f5", width: isMobileOnly ? "120px !important" : "230px !important", textAlign: "center", height: isMobileOnly ? "120px !important" : "230px !important", overflow: "hidden"}}>
              <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                <img
                    style={{maxWidth: isMobileOnly ? "120px !important" : "230px !important"}}
                    className="default-img"
                    src={(product.firstImageUrl != "" ? product.firstImageUrl : "https://img.therunningcollective.fr/fond-prochainement-disponible-jpeg-jpeg")}
                    alt={product.name + " au meilleur prix !"}
                />
              </a>
              {discount > 0  && discount !== 100 ? (
                  <div className="product-img-badges">
                    {discount > 0 && discount !== 100 ? (
                        <span className="purple" style={{backgroundColor : 'black', color : 'white', fontSize: "11px"}}>-{discount}%</span>
                    ) : (
                        ""
                    )}
                  </div>
              ) : (
                  ""
              )}

              {product.availableSoon  && discount == 0 ? (
                  <div className="product-img-badges">
                    <span className="purple" style={{backgroundColor : 'red', color : 'white'}}>Bientôt disponible</span>
                  </div>
              ) : (
                  ""
              )}

            {product.soonOutOfStock ? (
                  <div className="product-img-badges">
                    {
                      isMobileOnly ? 
<                     span className="purple" style={{backgroundColor : 'orange', fontSize: "11px", color : 'white',
                        marginTop : (discount > 0  && discount !== 100) ? "25px" : 0}}>Dernières tailles</span>
                      : <span className="purple" style={{backgroundColor : 'orange', color : 'white',
                      marginTop : (discount > 0  && discount !== 100) ? "25px" : 0}}>Dernières tailles disponibles</span>
                    }
                    
                  </div>
              ) : (
                  ""
              )}

              {product.outOfStock ? (
                  <div className="product-img-badges">
                    {
                      isMobileOnly ? 
<                     span className="purple" style={{backgroundColor : 'red', fontSize: "11px", color : 'white'}}>Rupture de stock</span>
                      : <span className="purple" style={{backgroundColor : 'red', color : 'white'}}>Rupture de stock</span>
                    }
                    
                  </div>
              ) : (
                  ""
              )}  

            </div>
            <div className="product-content-2">
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2">
                  {discount > 3 && discount !== 100 ? (
                      <Fragment>
                     <span style={{fontWeight: '600', marginRight: "10px"}}>
                       {finalDiscountedPrice + currency.currencySymbol}
                     </span>
                        <span style={{fontWeight: '600', fontSize: "10px", color: "gray", textDecoration: "line-through"}}>
                       {finalProductPrice + currency.currencySymbol}
                     </span>
                      </Fragment>
                  ) : (
                      <span style={{fontWeight: '600'}}>{product.price + currency.currencySymbol} </span>
                  )}
                  <h3 style={{lineHeight: "13px"}}>
                    <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                      {product.name}
                    </a>
                  </h3>
                  <div>
                    <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                      <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>{product.gender == "Men" ? "Homme" : product.gender == "Women" ? "Femme" : "Unisexe"}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

ProductGridSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingleTwo;
