import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";

const PimsterWidget = ({productName, moduleID, isProductPage}) => {
    
    const htmlContent = '<pimster-gallery-widget \
                            company="therunningcollective" \
                            product='+productName+' \
                            moduleId='+moduleID+' \
                            display="round" \
                            borderColor="black" \
                            cookieConsent="accepted" \
                            animations=\'["onHover"]\'> \
                        </pimster-gallery-widget>';

  return <div style={{textAlign: isProductPage ? "left" : "center", height: "133px", marginLeft: isProductPage ? "135px" : ""}}>{ReactHtmlParser(htmlContent)}</div>;
};

PimsterWidget.propTypes = {
    productName: PropTypes.any,
    moduleID: PropTypes.any,
    isProductPage: PropTypes.any,
};

export default PimsterWidget;