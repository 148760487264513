import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {setActiveBrandsSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopTag = ({ location, currentQuery, setCurrentQuery, tags, getSortParams }) => {
    const [hide, setHide] = useState(false)
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [display, setDisplay] = useState('none')
    const { t } = useTranslation()

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-tag-brand button"
    );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    if (query && query.brands) {
        let sizes = query.brands.split('_');
        sizes.map(size => {
            var element = document.getElementById(size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

    function constructAddQueryPath(query, size) {
        if (query && query.brands) {
            return query.brands + "_" + size
        }
        return size
    }

    function constructRemoveQueryPath(query, sizeToRemove) {
        if (query && query.brands) {
            let sizes = query.brands.split('_');
            sizes = sizes.filter(size => {
                return size !== sizeToRemove;
            })
            if (sizes.length > 0) {
                let newSizeQuery = ""
                sizes.map((size, index) => {
                    if (index == 0){
                        newSizeQuery += size
                    } else {
                        newSizeQuery +=  "_" + size
                    }
                })
                return newSizeQuery
            } else {
                return null
            }

        }
        return null
    }

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{t('brands')} &nbsp;
                    </h4>
                    <div className="sidebar-widget-tag-brand mt-10" style={{display: 'block'}}>
                        {tags ? (
                            <ul>
                                {tags.map((tag, key) => {
                                    return (
                                        <li key={key}>
                                            <button
                                                id={tag}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("brand", "");
                                                        setCurrentQuery({ ...currentQuery, brands: constructRemoveQueryPath(query, tag)});
                                                    } else {
                                                        getSortParams("brand", tag);
                                                        setCurrentQuery({ ...currentQuery, brands: constructAddQueryPath(query, tag)});
                                                    }
                                                    setActiveBrandsSort(e);
                                                }}
                                            >
                                                {tag}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{t('brands')} </h4>
                    <div className="sidebar-widget-tag-brand mt-10">
                        {tags ? (
                            <ul>
                                {tags.map((tag, key) => {
                                    return (
                                        <li key={key}>
                                            <button
                                                id={tag}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("brand", "");
                                                        setCurrentQuery({ ...currentQuery, brands: constructRemoveQueryPath(query, tag)});
                                                    } else {
                                                        getSortParams("brand", tag);
                                                        setCurrentQuery({ ...currentQuery, brands: constructAddQueryPath(query, tag)});
                                                    }
                                                    setActiveBrandsSort(e);
                                                }}
                                            >
                                                {tag}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

ShopTag.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
    currentQuery: PropTypes.object,
    setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopTag;
