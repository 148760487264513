import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const BannerOneSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className="col-lg-4 col-md-4">
      <div
        className={`single-banner ${spaceBottomClass ? spaceBottomClass : ""}`}
      >
        <Link to={data.url}>
          <img src={data.image} alt={data.title + " au meilleur prix !"} />
        <div className="banner-content">
          <span
              style={{fontWeight: '500' ,fontFamily: '"Poppins", sans-serif', fontSize: '30px', lineHeight: '36px' ,color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}}
          >
            {data.title}
          </span>
          <h4
              style={{color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}}
          >
            {data.subtitle} <span>{data.price}</span>
          </h4>
          <a
              style={{color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black', borderColor: rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}} href={data.url}>
            <i style={{color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}} className="fa fa-long-arrow-right" />
          </a>
        </div>
        </Link>
      </div>
    </div>
  );
};

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

BannerOneSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default BannerOneSingle;
