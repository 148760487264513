import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import BannerOne from "../../wrappers/banner/BannerOne";
import HeroSliderNine from "../../wrappers/hero-slider/HeroSliderNine";
import TabProductEleven from "../../wrappers/product/TabProductEleven";
import TabPrivateSalesEleven from "../../wrappers/product/TabPrivateSalesEleven";
import 'react-ig-feed/dist/index.css';
import ReactGA from "react-ga4";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";
import {BrowserView, MobileView, isTablet} from 'react-device-detect';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import {Divider, IconButton, InputBase, Paper, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import {Helmet} from "react-helmet";
import PodcastsFeatured from "../../wrappers/podcasts/PodcastsFeatured";
import TrustBoxHomePage from "../../components/TrustboxHomePage";
import {GetUserData} from "../../App";
import ReactPixel from "react-facebook-pixel";
import axios from "axios";
import Box from "@mui/material/Box";
import {CookieBannerUniversal} from "react-cookie-banner";
import MobileBanner from "react-mobile-banner";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {sleep} from "../../helpers/tools";
import {useTranslation} from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import HeroSliderNineSingle from "../../components/hero-slider/HeroSliderNineSingle";
import HomeRacesFeatured from "../../wrappers/races/HomeRacesFeatured";
import TabProductElevenInfinite from "../../wrappers/product/TabProductElevenInfinite";
import PimsterWidget from "../../components/PimsterWidget";
import TabForecastsEleven from "../../wrappers/product/TabForecastsEleven";
import TabGuideEleven from "../../wrappers/product/TabGuideEleven";
import { Geolocation } from '@capacitor/geolocation';
import TabPhysicalShopsEleven from "../../wrappers/product/TabPhysicalShopsEleven";


export function setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const printCurrentPosition = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    return coordinates.coords
};

export const checkPermissions = async () => {
    const permissionStatus = await Geolocation.checkPermissions();
    return permissionStatus
};

export const requestPermissions = async () => {
    const permissionStatus = await Geolocation.requestPermissions();
    return permissionStatus
};

const HomeFashionTwo = ({ location, origin }) => {
  ReactGA.send({ hitType: "pageview", page: location.pathname });
    useEffect(() => {
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
    }, [])
    const { t, i18n } = useTranslation();

    const [avalaibleProfiles, setAvalaibleProfiles] = useState([]);
    const [bannerShow, setBannerShow] = useState(false);
    const [privateSales, setPrivateSales] = useState([]);

    const [loading, setLoading] = useState(true);
    const shopType =""

    const [modalShow, setModalShow] = useState(false);
    const [headerPadding2, setHeaderPadding2] = useState()
    const [tags, setTags] = useState([]);
    const [forecasts, setForecasts]  = useState(null);
    const [guideArticles, setGuideArticles] = useState([]);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loadingPhysicalShops, setLoadingPhysicalShops] = useState(false);
    const [physicalShops, setPhysicalShops] = useState(null)

    const fetchProductsSlidesData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/home-appearance?shopType=home&appearanceType=/appearance/home/best-products'

        axios
            .get(query)
            .then((res) => {
                BuildFirstProducts(res.data, setTags)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchProductsSlidesData();
    }, []);

    useEffect(() => {
        sleep(2300).then(
            () => setModalShow(true)
        )
    }, [])

    const closeAppliBanner = () => {
        setWithExpiry("trc-banner-appli", "false", 566400000)
        setBannerShow(false)
    }

    useEffect(() => {
        let trcBanerValue = getWithExpiry("trc-banner-appli")
        if (!trcBanerValue){
            setBannerShow(true)
        }
    }, [])

    const [avalaibleInfiniteProductsSlides, setAvalaibleInifiteProductsSlides] = useState([]);

    const fetchInfiniteProductsSlidesData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/home-appearance?shopType=home&appearanceType=/appearance/home/infinite-products'

        axios
            .get(query)
            .then((res) => {
                setAvalaibleInifiteProductsSlides(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchSlidesData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/home-appearance?shopType='+shopType+'&appearanceType=/appearance/home/profil'

        axios
            .get(query)
            .then((res) => {
                setAvalaibleProfiles(res.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchPrivateSales = () => {
        let query = 'https://public-front-api.therunningcollective.fr/private-sales'

        axios
            .get(query)
            .then((res) => {
                setPrivateSales(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchTestingData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/small-guide-posts'

        axios
            .get(query)
            .then((res) => {
                setGuideArticles(res.data);

            })
            .catch((err) => {
                console.log(err);
                window.prerenderReady = true
            })
            .finally(() => {
                window.prerenderReady = true
            });
    };

    const fetchForecasts = () => {
        let query = 'https://public-front-api.therunningcollective.fr/forecasts'

        axios
            .get(query)
            .then((res) => {
                setForecasts(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const launchPosition = () => {
        setLoadingPhysicalShops(true)
        checkPermissions().then(
            (res) => {
                if (res.state !== "denied"){
                    printCurrentPosition().then(
                        (resPosition) => {
                            axios.get('https://api.positionstack.com/v1/reverse?language=fr&access_key=1faa7b9820f65237c949be6387549e47&limit=1&query='+resPosition.latitude+','+resPosition.longitude)
                                .then((res) => {
                                    if (res.data && res.data.data && res.data.data.length > 0){
                                        setLatitude(res.data.data[0].latitude)
                                        setLongitude(res.data.data[0].longitude)
                                        setLoadingPhysicalShops(false)
                                    }
                                }).catch(
                                (error) => {
                                    var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
                                    axios.get(url).then((res) => {
                                        if (res.data){
                                            res.data.location = window.location.href
                                            setLatitude(res.data.lat)
                                            setLongitude(res.data.lon)
                                            setLoadingPhysicalShops(false)
                                    }})  
                                }
                            )
                        }
                    ).catch(() => {
                        var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
                        axios.get(url).then((res) => {
                            if (res.data){
                                res.data.location = window.location.href
                                setLatitude(res.data.lat)
                                setLongitude(res.data.lon)
                                setLoadingPhysicalShops(false)
                            }})  
                    })
                } else {
                    requestPermissions().then(
                        (res) => {
                            if (res.location == "denied"){
                                var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
                                axios.get(url).then((res) => {
                                    if (res.data){
                                        res.data.location = window.location.href
                                        setLatitude(res.data.lat)
                                        setLongitude(res.data.lon)
                                        setLoadingPhysicalShops(false)
                                    }                
                                }).catch(() => {
                                    setLoadingPhysicalShops(false)
                                })
                            }
                        }
                    ).catch(
                        (res) => {
                            
                        }
                    )
    
                }
            }
        ).catch(
            (res) => {
                var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
                axios.get(url).then((res) => {
                    if (res.data){
                        res.data.location = window.location.href
                        setLatitude(res.data.lat)
                        setLongitude(res.data.lon)
                        setLoadingPhysicalShops(false)
                    }   
            }).catch(() => {
                setLoadingPhysicalShops(false)
            })
        })
      }

      const getPhysicalShops = (latitude, longitude) => {
        ReactGA.event({
            category: 'Physical Shops',
            action: 'Get physical shops'
        });

        let query = 'https://public-front-api.therunningcollective.fr/physical-shops-activations?latitude='+latitude+"&longitude="+longitude
        axios.get(query, null, {})
            .then((res) => {
                setPhysicalShops(res.data);
                setLoadingPhysicalShops(false)
            }).catch(function (error) {
                setLoadingPhysicalShops(false);
            if (error.response) {
                // Request made and server responded
            }
        });
    };

    useEffect(() => {
        if (latitude, longitude){
            getPhysicalShops(latitude, longitude);
        }
    }, [latitude, longitude])

    useEffect(() => {
        fetchSlidesData();
        fetchInfiniteProductsSlidesData();
        fetchPrivateSales();
        fetchForecasts();
        fetchTestingData();
        launchPosition();
    }, []);

    useEffect(() => {
        GetUserData()
    }, []);
  return (
    <Fragment>
      <MetaTags>
          <Helmet>
              <title>The Running Collective - {t('description')}</title>
              <meta
                  name="description"
                  content={"The Running Collective, " + t('metatitle')}
              />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:title" content="The Running Collective" />
              <meta property="og:type" content="website" />
              <meta property="og:local" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()} />
              <meta property="og:site_name" content="The Running Collective" />
              <meta property="og:description"
                    content={"The Running Collective, " + t('metatitle')}
              />
              <meta property="og:image" content="https://img.therunningcollective.fr/head-04.jpg" />
              <meta property="robots" content="index, follow"/>
              <script type="application/ld+json">
                  {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      "url": "https://app.therunningcollective."+origin+"",
                      "logo": "https://app.therunningcollective."+origin+"/assets/img/noune/logo-trc.png"
                  })}
              </script>
          </Helmet>
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        location={location}
        homePage={true}
      >
        <div style={{display: 'none'}}>
            <h1>{t('description')}</h1>
            <h2>{t('h2home')}</h2>
        </div>

        {/* hero slider */}
          <MobileView>
              <HeroSliderNine shopType="home" origin={origin}/>
              
              <div style={{marginTop: '10px', marginBottom: "20px"}}>
                  <div style={{textAlign: 'center'}}>Comment fonctionne le comparateur :</div>
                  <PimsterWidget productName={"page-daccueil"} moduleID={"3052"}/>
              </div>

              <div style={{marginTop: '10px', marginBottom: "20px"}}>
                  <div style={{textAlign: 'center'}}>{t('usersreview')} </div>
                  <TrustBoxHomePage/>
              </div>

              {
                forecasts && forecasts.length > 0 ?
                <TabForecastsEleven
                  category="kids"
                  spaceTopClass="pt-10"
                  spaceBottomClass="pb-20"
                  sectionTitle="Athlé Pronos"
                  shopType=""
                  origin={origin}
                  forecasts={forecasts}
                />
                : null
              }

              <TabProductEleven
                  category="kids"
                  spaceTopClass="pt-20"
                  sectionTitle={t('popularproducts')}
                  shopType=""
                  origin={origin}
              />

            {
                physicalShops && physicalShops.length > 0 ?
                    physicalShops.map(physicalShop => {
                        return(
                            <TabPhysicalShopsEleven
                                category="kids"
                                spaceTopClass="pt-10"
                                spaceBottomClass="pb-20"
                                sectionTitle={physicalShop.name}
                                shopType=""
                                origin={origin}
                                physicalShops={physicalShop.shops}
                                titleLink={physicalShop.name}
                            />
                        )
                    })
                : null
            }

             {
                guideArticles && guideArticles.length > 0 ?
                    <TabGuideEleven
                        category="kids"
                        spaceTopClass="pt-10"
                        spaceBottomClass="pb-20"
                        sectionTitle="Guide du coureur"
                        shopType=""
                        origin={origin}
                        guideArticles={guideArticles}
                    />
                : null
              }
            
              {
                privateSales && privateSales.length > 0 ?
                <TabPrivateSalesEleven
                  category="kids"
                  spaceTopClass="pt-10"
                  spaceBottomClass="pb-20"
                  sectionTitle="Ventes privées"
                  shopType=""
                  origin={origin}
                  privateSales={privateSales}
                />
                : null
              }

              {
                  avalaibleInfiniteProductsSlides && avalaibleInfiniteProductsSlides.length > 0 ?
                      avalaibleInfiniteProductsSlides.map(avalaibleInfiniteProductsSlide => {
                          return (
                              <div>
                                  <div style={{height: "390px"}}>
                                      <HeroSliderNineSingle
                                          style={{paddingBottom: "20px"}}
                                          marginTop="240px"
                                          data={avalaibleInfiniteProductsSlide}
                                          key={0}
                                          sliderClass="swiper-slide"
                                      />
                                  </div>
                                  <TabProductElevenInfinite
                                      category="kids"
                                      spaceBottomClass="pt-30 pb-10"
                                      sectionTitle={avalaibleInfiniteProductsSlide.subtitle}
                                      shopType=""
                                      filter={avalaibleInfiniteProductsSlide.filter}
                                      productsList={avalaibleInfiniteProductsSlide.products}
                                  />
                              </div>
                          )
                      })
                      : null
              }

              <HomeRacesFeatured/>
              {/*<BlogFeatured/>*/}
              <BrandLogoSliderOne spaceBottomClass="pb-10" />

          </MobileView>

          <BrowserView>
              <HeroSliderNine shopType="home" origin={origin}/>

              <Row className="firstProducts mt-10 justify-content-md-center" style={{width: "100%", textAlign: "center", backgroundColor: "white", paddingTop: headerPadding2}}>
                  {tags}
              </Row>

              <div style={{marginTop: '10px', marginBottom: "20px"}}>
                  <div style={{textAlign: 'center'}}>Comment fonctionne le comparateur :</div>
                  <PimsterWidget productName={"page-daccueil"} moduleID={"3052"}/>
              </div>

              <div style={{marginTop: '10px'}}>
                  <div style={{textAlign: 'center'}}>{t('usersreview')} </div>
                  <TrustBoxHomePage/>
              </div>

              {
                forecasts && forecasts.length > 0 ?
                <TabForecastsEleven
                  category="kids"
                  spaceTopClass="pt-10"
                  spaceBottomClass="pb-20"
                  sectionTitle="Athlé Pronos"
                  shopType=""
                  origin={origin}
                  forecasts={forecasts}
                />
                : null
              }

              <TabProductEleven
                  category="kids"
                  spaceTopClass="pt-10"
                  spaceBottomClass="pb-20"
                  sectionTitle={t('popularproducts')}
                  shopType=""
                  origin={origin}
              />

            {
                physicalShops && physicalShops.length > 0 ?
                    physicalShops.map(physicalShop => {
                        return(
                            <TabPhysicalShopsEleven
                                category="kids"
                                spaceTopClass="pt-10"
                                spaceBottomClass="pb-20"
                                sectionTitle={physicalShop.name}
                                shopType=""
                                origin={origin}
                                physicalShops={physicalShop.shops}
                                titleLink={physicalShop.name}
                            />
                        )
                    })
                : null
            }

            {
                guideArticles && guideArticles.length > 0 ?
                    <TabGuideEleven
                        category="kids"
                        spaceTopClass="pt-10"
                        spaceBottomClass="pb-20"
                        sectionTitle="Guide du coureur"
                        shopType=""
                        origin={origin}
                        guideArticles={guideArticles}
                    />
                : null
              } 

              {
                privateSales && privateSales.length > 0 ?
                <TabPrivateSalesEleven
                  category="kids"
                  spaceTopClass="pt-10"
                  spaceBottomClass="pb-20"
                  sectionTitle="Ventes privées"
                  shopType=""
                  origin={origin}
                  privateSales={privateSales}
                />
                : null
              }


              {
                  avalaibleInfiniteProductsSlides && avalaibleInfiniteProductsSlides.length > 0 ?
                      avalaibleInfiniteProductsSlides.map((avalaibleInfiniteProductsSlide, index) => {
                          return (
                              index % 2 == 0 ?
                                  <Row style={{marginLeft: "15px", marginRight: "15px", marginTop:"15px"}}>
                                      <Col xs={4}>
                                          <div style={{height: "390px"}}>
                                              <HeroSliderNineSingle
                                                  style={{paddingBottom: "20px"}}
                                                  marginTop="240px"
                                                  data={avalaibleInfiniteProductsSlide}
                                                  key={0}
                                                  sliderClass="swiper-slide"
                                                  isSide={true}
                                              />
                                          </div>
                                      </Col>
                                      <Col xs={8}>
                                          <TabProductElevenInfinite
                                              category="kids"
                                              spaceBottomClass="pb-10"
                                              sectionTitle={avalaibleInfiniteProductsSlide.subtitle}
                                              shopType=""
                                              filter={avalaibleInfiniteProductsSlide.filter}
                                              productsList={avalaibleInfiniteProductsSlide.products}
                                              isSide={true}
                                          />
                                      </Col>
                                  </Row>
                                  :

                                  <Row style={{marginLeft: "15px", marginRight: "15px", marginTop:"15px"}}>
                                      <Col xs={8}>
                                          <TabProductElevenInfinite
                                              category="kids"
                                              spaceBottomClass="pb-10"
                                              sectionTitle={avalaibleInfiniteProductsSlide.subtitle}
                                              shopType=""
                                              filter={avalaibleInfiniteProductsSlide.filter}
                                              isSide={true}
                                              productsList={avalaibleInfiniteProductsSlide.products}
                                          />
                                      </Col>
                                      <Col xs={4}>
                                          <div style={{height: "390px"}}>
                                              <HeroSliderNineSingle
                                                  style={{paddingBottom: "20px"}}
                                                  marginTop="240px"
                                                  data={avalaibleInfiniteProductsSlide}
                                                  key={0}
                                                  sliderClass="swiper-slide"
                                                  isSide={true}
                                              />
                                          </div>
                                      </Col>
                                  </Row>

                          )
                      })
                      : null

              }
              {/* blog featured */}
              <HomeRacesFeatured/>

              {/*<BlogFeatured/>*/}
              <PodcastsFeatured/>

              <BrandLogoSliderOne spaceBottomClass="pb-10" />

          </BrowserView>


        {/* tab product */}




          {/*<InstagramFeed token={token}  counter="6"/>*/}
        <br/>
      </LayoutOne>
    </Fragment>
  );
};

export function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                p: 1,
                m: 1,
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function extractUrl(url){
    const newURL = url.replace('https://app.therunningcollective.fr', process.env.PUBLIC_URL);
    return newURL
}

export const BuildFirstProducts = (avalaibleProductsSlides, set) => {
    let newCategoriesItems = []
    avalaibleProductsSlides.map(avalaibleProductsSlide => {
        newCategoriesItems.push(
            <Col xs={1}
                 onClick={() => {
                     ReactGA.event({
                         category: 'FirstProductHomePage',
                         action: 'Redirect to ' + avalaibleProductsSlide.title
                     });
                 }}
                 style={{
                    cursor: "pointer",
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${avalaibleProductsSlide.contrast != "" ? avalaibleProductsSlide.contrast : "0"}),rgba(0, 0, 0, ${avalaibleProductsSlide.contrast != "" ? avalaibleProductsSlide.contrast : "0"})) , url(${avalaibleProductsSlide.image})`,
                    height: "60px",
                    width: "140px",
                    backgroundSize: "100%",
                    backgroundPositionY: "60px",
                    marginLeft: "10px"
                }}>
                <div
                    onClick={() => {window.location = extractUrl(avalaibleProductsSlide.url)}}
                >
                <div style={{width: "100%", marginTop: "10px"}}>
                    <div style={{textAlign: "center"}}>
                        <div>
                            <div className="slider-content-2 slider-animated-1">
                                <div className="slider-h3 animated" style={{
                                    color: avalaibleProductsSlide.textColor ? rgbToHex(avalaibleProductsSlide.textColor.r, avalaibleProductsSlide.textColor.g, avalaibleProductsSlide.textColor.b) : 'black',
                                    fontSize: "11px",
                                    lineHeight: "15px",
                                    textAlign: "center",
                                    padding: "1rem"
                                }}>
                                    <span className={"trait"} style={{backgroundColor: avalaibleProductsSlide.textColor ? rgbToHex(avalaibleProductsSlide.textColor.r, avalaibleProductsSlide.textColor.g, avalaibleProductsSlide.textColor.b) : 'black'}}/>
                                    {avalaibleProductsSlide.title}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Col>

        )
    })
    set(newCategoriesItems)
}

export default HomeFashionTwo;


export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        bottom: "10px",
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: 'black',
    },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        fontFamily: "ITC Avant Garde Gothic",
        fontSize: theme.typography.pxToRem(14),
        color: 'rgba(O, 0, 0, 0.7)',
        '&.Mui-selected': {
            color: '#000',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        tabpanel: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    }),
);