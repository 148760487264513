import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveUsageSort, deleteActiveusageSort, setActiveUsageSort, setActiveusageSort} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopShoeLongFlexibility = ({ location, currentQuery, setCurrentQuery, usages, getSortParams, isHome }) => {
  const [isSmallScreen, setSmallScreen] = useState(false);
    const { t } = useTranslation()

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-long-flexibility button"
  );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });

    if (query && query.longFlexibility) {
        let longFlexibility = query.longFlexibility.split('_');
        longFlexibility.map(size => {
            var element = document.getElementById("longFlexibility_"+size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Flexibilité longitudinale (facultatif) :" : "Flexibilité longitudinale :"}&nbsp;</h4>
                    <div className="sidebar-widget-tag-long-flexibility mt-10" style={{display: 'block'}}>
                        {usages ? (
                            <ul>
                                {usages.map((usage, key) => {
                                    return (
                                        <li key={"longFlexibility_"+key}>
                                            <button
                                                className="toto"
                                                id={"longFlexibility_"+usage}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("usage", "");
                                                        setCurrentQuery({ ...currentQuery, longFlexibility: ""});
                                                        deleteActiveUsageSort(e);
                                                    } else {
                                                        getSortParams("usage", usage);
                                                        setCurrentQuery({ ...currentQuery, longFlexibility: usage});
                                                        setActiveUsageSort(e);
                                                    }
                                                }}
                                            >
                                                {t(usage.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Flexibilité longitudinale (facultatif) :" : "Flexibilité longitudinale :"}&nbsp;</h4>
                    <div className="sidebar-widget-tag-long-flexibility mt-10">
                        {usages ? (
                            <ul>
                                {usages.map((usage, key) => {
                                    return (
                                        <li key={"longFlexibility_"+key}>
                                            <button
                                                className="toto"
                                                id={"longFlexibility_"+usage}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("usage", "");
                                                        setCurrentQuery({ ...currentQuery, longFlexibility: ""});
                                                        deleteActiveUsageSort(e);
                                                    } else {
                                                        getSortParams("usage", usage);
                                                        setCurrentQuery({ ...currentQuery, longFlexibility: usage});
                                                        setActiveUsageSort(e);
                                                    }
                                                }}
                                            >
                                                {t(usage.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

export function getLabel(usage, t){
    switch (usage){
        case "Women":
            return t('usage')
        case "Men":
            return "Homme"
        case "Unisex":
            return "Non Genré"
        default:
            return
    }
}

export function ConstructRemoveUsageQueryPath(query, sizeToRemove) {
    if (query && query.usages) {
        let usages = query.usages.split('_');
        usages = usages.filter(size => {
            return size !== sizeToRemove;
        })
        if (usages.length > 0) {
            let newSizeQuery = ""
            usages.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopShoeLongFlexibility.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopShoeLongFlexibility;
