import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveMotorSort, deleteActivemotorSort, setActiveMotorSort, setActivemotorSort} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopMotorPref = ({ location, currentQuery, setCurrentQuery, motors, getSortParams, isHome }) => {
  const [isSmallScreen, setSmallScreen] = useState(false);
    const { t } = useTranslation()

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-motor-pref button"
  );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });

    if (query && query.motor) {
        let motors = query.motor.split('_');
        motors.map(size => {
            var element = document.getElementById("motor_"+size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

    function constructAddQueryPath(query, size) {
        if (query && query.motor) {
            return query.motor + "_" + size
        }
        return size
    }

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quelle est sa préférence motrice ?" : "Préférence motrice :"} &nbsp;
                    </h4>
                    <div className="sidebar-widget-tag-motor-pref mt-10" style={{display: 'block'}}>
                        {motors ? (
                            <ul>
                                {motors.map((motor, key) => {
                                    return (
                                        <li key={"motor_"+key}>
                                            <button
                                                className="toto"
                                                id={"motor_"+motor}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("motor", "");
                                                        setCurrentQuery({ ...currentQuery, motor: ""});
                                                        deleteActiveMotorSort(e);
                                                    } else {
                                                        getSortParams("motor", motor);
                                                        setCurrentQuery({ ...currentQuery, motor: motor, types: "", pains: "", analysis: "", shoeType: "", shoeDistance :"", isPain : ""});
                                                        setActiveMotorSort(e);
                                                    }
                                                }}
                                            >
                                                {t(motor.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quelle est sa préférence motrice ?" : "Préférence motrice :"} &nbsp;</h4>
                    <div className="sidebar-widget-tag-motor-pref mt-10">
                        {motors ? (
                            <ul>
                                {motors.map((motor, key) => {
                                    return (
                                        <li key={"motor_"+key}>
                                            <button
                                                className="toto"
                                                id={"motor_"+motor}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("motor", "");
                                                        setCurrentQuery({ ...currentQuery, motor: ""});
                                                        deleteActiveMotorSort(e);
                                                    } else {
                                                        getSortParams("motor", motor);
                                                        setCurrentQuery({ ...currentQuery, motor: motor, types: "", pains: "", analysis: "", shoeType: "", shoeDistance :"", isPain : ""});
                                                        setActiveMotorSort(e);
                                                    }
                                                }}
                                            >
                                                {t(motor.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

export function ConstructRemovemotorQueryPath(query, sizeToRemove) {
    if (query && query.motor) {
        let motors = query.motor.split('_');
        motors = motors.filter(size => {
            return size !== sizeToRemove;
        })
        if (motors.length > 0) {
            let newSizeQuery = ""
            motors.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopMotorPref.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopMotorPref;
