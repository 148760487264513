import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {setActiveSizesSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopSize = ({ location, currentQuery, setCurrentQuery, sizes, getSortParams }) => {
  const [hide, setHide] = useState(false)
  const [isSmallScreen, setSmallScreen] = useState(false);
  const [display, setDisplay] = useState('none')
  const { t } = useTranslation()

  if (sizes){
    sizes = sortSize(sizes)
  }

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-size button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
  if (query && query.sizes) {
    let sizes = query.sizes.split('_');
    sizes.map(size => {
      var element = document.getElementById(size);
      if (element){
        element.classList.add("active")
      }
    })
  }

  useEffect(() => {
    const matchResult = window.matchMedia("(max-width: 992px)");
    if (matchResult.matches) {
      setSmallScreen(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", function() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        setSmallScreen(false)
      }
    })
  }, [])

  function constructAddQueryPath(query, size) {
    if (query && query.sizes) {
      return query.sizes + "_" + size
    }
    return size
  }

  function constructRemoveQueryPath(query, sizeToRemove) {
    if (query && query.sizes) {
      let sizes = query.sizes.split('_');
      sizes = sizes.filter(size => {
        return size !== sizeToRemove;
      })
      let newSizeQuery = ""
      sizes.map((size, index) => {
        if (index == 0){
          newSizeQuery += size
        } else {
          newSizeQuery +=  "_" + size
        }
      })
      return newSizeQuery
    }
    return null
  }

  return (
    <div className="sidebar-widget mt-10">
      {
        isMobileOnly || isSmallScreen ?
            <div>
              <h4 className="pro-sidebar-title">{t('size')} &nbsp;
              </h4>
              <div className="sidebar-widget-tag-size mt-10" style={{display: "block",
                maxHeight: "200px", overflow: "auto"}}>
                {sizes ? (
                    <ul>
                      {sizes.map((size, key) => {
                        return (
                            <li key={key}>
                              <button
                                  id={size}
                                  onClick={e => {
                                    if (e.currentTarget.classList.contains('active')) {
                                      getSortParams("size", "");
                                      setCurrentQuery({ ...currentQuery, sizes: constructRemoveQueryPath(query, size)});
                                    } else {
                                      getSortParams("size", size);
                                      setCurrentQuery({ ...currentQuery, sizes: constructAddQueryPath(query, size)});
                                    }
                                    setActiveSizesSort(e);
                                  }}
                              >
                                {size}
                              </button>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune taille disponible"
                )}
              </div>

            </div>
            :
            <div>
              <h4 className="pro-sidebar-title">{t('size')} </h4>
              <div className="sidebar-widget-tag-size mt-10"  style={{maxWidth: '320px', maxHeight: '300px', overflow: 'auto'}}>
                {sizes ? (
                    <ul>
                      {sizes.map((size, key) => {
                        return (
                            <li key={key}>
                              <button
                                  id={size}
                                  onClick={e => {
                                    if (e.currentTarget.classList.contains('active')) {
                                      getSortParams("size", "");
                                      setCurrentQuery({ ...currentQuery, sizes: constructRemoveQueryPath(query, size)});
                                    } else {
                                      getSortParams("size", size);
                                      setCurrentQuery({ ...currentQuery, sizes: constructAddQueryPath(query, size)});
                                    }
                                    setActiveSizesSort(e);
                                  }}
                              >
                                {size}
                              </button>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune taille disponible"
                )}
              </div>
            </div>

      }
    </div>
  );
};

let weights = {
  'XXS':1,
  'XS':2,
  'S':3,
  'M':4,
  'L':5,
  'XL':6,
  'XXL':7,
  '2XL': 8
};

export function sortSize(array){
  return array.sort((a,b)=>{
    if(typeof(a)=="number" && typeof(b)=="number")
      return a-b;
    else
      return weights[a]-weights[b]
  });
}

ShopSize.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
  location: PropTypes.string,
};

export default ShopSize;
