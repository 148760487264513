import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {Col, Row} from "react-bootstrap";

const PodcastFeaturedSingle = ({ singlePost }) => {
  return (
    <div className="col-lg-2 col-sm-6">
      <div className="blog-wrap scroll-zoom">
        <div className="blog-img" style={{maxHeight: '360px', overflow: 'hidden'}}>
          <Link to={process.env.PUBLIC_URL + 'podcast/' + singlePost.id}>
            <img src={singlePost.image} alt={"Podcast "+singlePost.name} style={{width: "100%", borderRadius: "5px", border: "1px solid lightgray"}}/>
          </Link>
        </div>
        <div className="container" style={{
          position: "relative", marginTop: "10px"}}>
          <Row>
            {
              singlePost.tags && singlePost.tags.length > 2 ?
                  singlePost.tags.slice(0,2).map(tag => {
                    return(
                        <Col xs={5} style={{
                          backgroundColor:"black", color:"white", padding:"1px", fontSize:"10px", textAlign:"center",
                          marginLeft:"10px", borderRadius:"5px"
                        }}>
                          {tag}
                        </Col>
                    )
                  })

                  : singlePost.tags && singlePost.tags.length > 0 ?
                      singlePost.tags.map(tag => {
                        return(
                            <Col xs={5} style={{
                              backgroundColor:"black", color:"white", padding:"1px", fontSize:"10px", textAlign:"center",
                              marginLeft:"10px", borderRadius:"5px"
                            }}>
                              {tag}
                            </Col>
                        )
                      }) : null
            }
          </Row>
        </div>
        <div className="blog-content-wrap">
          <div className="blog-content text-left" style={{margin: 0, position: "inherit"}}>
            <h3>
              <Link to={process.env.PUBLIC_URL + 'podcast/'+singlePost.id}>
                {singlePost.name}
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

PodcastFeaturedSingle.propTypes = {
  singlePost: PropTypes.object
};

export default PodcastFeaturedSingle;
