import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";
import {Col, Row} from "react-bootstrap";
import {GiPathDistance} from 'react-icons/gi'

const PhysicalShopsSingleTwo = ({
                                physicalShop,
                                currency,
                                sliderClassName,
                                spaceBottomClass,
                                colorClass,
                                titlePriceClass, isSide, index
                              }) => {
  return (
      <Fragment>
        <div
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
            style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
            onClick={() => {
              window.location.href= "/physical-shop/"+physicalShop.id
            }}
        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`sales-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="product-content-2" style={{display: isMobileOnly ? "flex": "inherit"}}>
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2">
                  <h3 style={{lineHeight: "13px", fontSize: "16px"}}>
                    <a>
                      <Row>
                        <Col xs={3}>
                          <img
                            style={{width: isMobileOnly ? "30px" : "60px", borderRadius: "5px"}}
                            src={(physicalShop.mapLogo != "" ? physicalShop.mapLogo : "")}
                            alt={physicalShop.name}
                          />
                        </Col>
                        <Col xs={9} style={{marginTop: isMobileOnly ? "" : "25px", fontSize: isMobileOnly ? "14px" : "17px"}}>
                          {physicalShop.name}
                        </Col>
                      </Row>
                      
                    </a>
                  </h3>
                  <div style={{lineHeight: "15px", marginTop: "5px"}}>
                      {
                        physicalShop.location ?
                          <a>
                            <div style={{color: "grey", fontWeight: 500, fontSize: isMobileOnly ? "11px" : "14px"}}>{physicalShop.location.address}</div>
                            <div style={{color: "grey", fontWeight: 500, fontSize: isMobileOnly ? "11px" : "14px "}}>{physicalShop.location.zipCode} - {physicalShop.location.town}</div>
                            <div style={{color: "grey", fontWeight: 500, fontSize: isMobileOnly ? "11px" : "14px "}}>{physicalShop.location.country}</div>
                          </a>
                        : null
                      }                      
                  </div>
                  <div style={{lineHeight: "15px", marginTop: "5px"}}>
                    {
                      physicalShop.distanceFromLocation ?
                      <a>
                        <div style={{color: "grey", fontWeight: 500, fontSize: isMobileOnly ? "11px" : "14px"}}>
                          <GiPathDistance/> {physicalShop.distanceFromLocation.toFixed(2)}km
                        </div>
                      </a>
                      : null
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

PhysicalShopsSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  physicalShop: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default PhysicalShopsSingleTwo;
