import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translations.json';
import translationFR from '../locales/fr/translations.json';
import translationES from '../locales/es/translations.json';
import translationIT from '../locales/it/translations.json';
import translationDE from '../locales/de/translations.json';

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    es: {
        translation: translationES
    },
    be: {
        translation: translationFR
    },
    de: {
        translation: translationDE
    }
};

i18n
    .use(initReactI18next)
    .init({
        debug: true, // pratique pour voir les événements dans la console pendant le développement
        resources,
        lng: window.location.origin && window.location.origin.includes("https://app.therunningcollective.") ?
            (
                window.location.origin.includes("https://app.therunningcollective.be") ? "fr"
                    : window.location.origin.split("https://app.therunningcollective.")[1]
            )
            : "fr",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;