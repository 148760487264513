import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveTypeSort, setActiveTypeSort} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopShoeType = ({ location, currentQuery, setCurrentQuery, getSortParams, isHome }) => {
  const [isSmallScreen, setSmallScreen] = useState(false);
  const [currentShoeType, setCurrentShoeType] = useState([])

    const { t } = useTranslation()

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-type button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
    });

    if (query && query.types) {
        let types = query.types.split('_');
        console.log(types)
        types.map(size => {    
            var element = document.getElementById("type_"+size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        setCurrentShoeType(calculateShoeType(currentQuery.motor, currentQuery.pains, currentQuery.isPain, currentQuery.isMotor));
    }, [currentQuery])

    useEffect(() => {
        filterButtons.forEach(item => {
            item.classList.remove("active");
        });
        if (currentQuery && currentQuery.types) {
            let types = currentQuery.types.split('_');
            types.map(size => {    
                var element = document.getElementById("type_"+size);
                if (element){
                    element.classList.add("active")
                }
            })
        }
    }, [currentShoeType])
   

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    
        window.addEventListener("resize", function() {
            if (window.matchMedia("(min-width: 992px)").matches) {
                setSmallScreen(false)
            }
        })
    }, [])
    

    function constructAddQueryPath(query, size) {
        if (query && query.types) {
            return query.types + "_" + size
        }
        return size
    }

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quel est le type de chaussures habituellement utilisées ?" : "Type de chaussures habituellement utilisées :"} &nbsp;</h4>
                    <div className="sidebar-widget-tag-type mt-10" style={{display: 'block'}}>
                        {currentShoeType ? (
                            <ul>
                                {currentShoeType.map((type, key) => {
                                    return (
                                        <li key={"type_"+key}>
                                            <button
                                                className="toto"
                                                id={"type_"+type}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("type", "");
                                                        setCurrentQuery({ ...currentQuery, types: ""});
                                                        deleteActiveTypeSort(e);
                                                    } else {
                                                        getSortParams("type", type);
                                                        setCurrentQuery({ ...currentQuery, types: type, analysis: ""});
                                                        setActiveTypeSort(e);
                                                    }
                                                }}
                                            >
                                                {t(type.toLowerCase())}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title">{isHome ? "Quel est le type de chaussures habituellement utilisées ?" : "Type de chaussures habituellement utilisées :"} &nbsp;</h4>
                    <div className="sidebar-widget-tag-type mt-10">
                        {currentShoeType ? (
                            <ul>
                                {currentShoeType.map((type, key) => {
                                    return (
                                        <li key={"type_"+key}>
                                            <button
                                                className="toto"
                                                id={"type_"+type}
                                                onClick={e => {
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("type", "");
                                                        setCurrentQuery({ ...currentQuery, types: ""});
                                                        deleteActiveTypeSort(e);
                                                    } else {
                                                        getSortParams("type", type);
                                                        setCurrentQuery({ ...currentQuery, types: type, analysis: ""});
                                                        setActiveTypeSort(e);
                                                    }
                                                }}
                                            >
                                                {type}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

export function calculateShoeType(motorPref, painType, isPain, isMotor){
    var array = []
    if (motorPref && (painType || isPain)){
      if(motorPref == "Terrien en cuisse" || motorPref == "Terrien en pied"){
        if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
          array = ["Avec drop réduit", "Avec drop haut"]
        } else if (isPain ==  "Non"){
          array = ["Coureur débutant", "Coureur régulier", "Axé performance"]
        }
      } else if(motorPref == "Aérien en cuisse" || motorPref == "Aérien en pied"){
        if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
          array = ["Avec drop réduit", "Avec drop haut"]
        } else if (isPain ==  "Non"){
          array = ["Avec drop réduit", "Avec drop haut", "Axé performance"]
        }
      }
    } else if (isMotor == "Non"){
        if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
          array = ["Avec drop réduit", "Avec drop haut"]
        } else if (isPain ==  "Non"){
          array = ["Avec drop réduit", "Avec drop haut", "Axé performance"]
        }
      }
    
    return array
  }

export function ConstructRemoveTypeQueryPath(query, sizeToRemove) {
    if (query && query.types) {
        let types = query.types.split('_');
        types = types.filter(size => {
            return size !== sizeToRemove;
        })
        if (types.length > 0) {
            let newSizeQuery = ""
            types.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopShoeType.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

export default ShopShoeType;
