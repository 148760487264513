import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import ProductGridSingleTwo from "../../components/product/ProductGridSingleTwo";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";

const ProductGridTwoInfinite = ({
  type,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  colorClass,
  titlePriceClass,
  shopType,
  origin,
  filter,
  isSide,
  productsList
}) => {
  const [products, setProducts] = useState({ products: [] });
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  const fetchData = (type, origin) => {
    if (origin && origin == "be"){
      origin = "fr"
    }

    let query
    

    if (productsList){
      let productsString = ""
      productsList.map((item, index)=> {
        if (item){
          if (index == 0){
            productsString += item
          } else {
            productsString += "_" + item
          }
        }
      })
      query = 'https://public-front-api.therunningcollective.fr/home-products?products='+productsString
      axios
        .get(query)
        .then((res) => {
          if(res.data && res.data.length > 7){
            setProducts(res.data.slice(0, 7));
          } else {
            setProducts(res.data);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      if (shopType!== ""){
        if (shopType == "athletisme"){
          if (type !== ""){
            query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&filters='+type+'&page=1&headCategory=/products/spikes&shopType='+shopType
          } else {
            query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&page=1&headCategory=/products/spikes&shopType='+shopType
          }
        } else {
          if (type !== ""){
            query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&filters='+type+'&page=1&headCategory=/products/shoes&shopType='+shopType
          } else {
            query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&page=1&headCategory=/products/shoes&shopType='+shopType
          }
        }
      } else {
        if (type !== ""){
          query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&filters='+type+'&page=1&headCategory=/products/shoes'
        } else {
          query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&page=1&headCategory=/products/shoes'
        }
      }
  
      if (filter && filter != ""){
        if(origin){
          query = 'https://public-front-api.therunningcollective.fr/products'+filter+'&lang='+origin
        } else {
          query = 'https://public-front-api.therunningcollective.fr/products'+filter
        }
      }
      axios
        .post(query)
        .then((res) => {
          if(res.data && res.data.length > 7){
            setProducts(res.data.slice(0, 7));
          } else {
            setProducts(res.data);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setloading(false);
        });

    }

    
  };

  useEffect(() => {
    fetchData(type, origin);
  }, [type, origin]);

  return (
    <Fragment>
      {!loading ? products && products.length >0 ? [].concat(...Array(5).fill(products)).map((product, index) => {
        if(product.showProduct) {
          return (
              <ProductGridSingleTwo
                  sliderClassName={sliderClassName}
                  spaceBottomClass={spaceBottomClass}
                  colorClass={colorClass}
                  product={product}
                  currency={currency}
                  addToCart={addToCart}
                  addToWishlist={addToWishlist}
                  addToCompare={addToCompare}
                  isSide={isSide}
                  index={index}
                  cartItem={
                    cartItems.filter((cartItem) => cartItem.id === product.id)[0]
                  }
                  wishlistItem={
                    wishlistItems.filter(
                        (wishlistItem) => wishlistItem.id === product.id
                    )[0]
                  }
                  compareItem={
                    compareItems.filter(
                        (compareItem) => compareItem.id === product.id
                    )[0]
                  }
                  key={product.id}
                  titlePriceClass={titlePriceClass}
              />
          );
        }
      }) : <span>Aucun produit disponible</span> : <span>Chargement en cours</span> }
      {error ? <span>Erreur durant le chargement des données</span> : null }
    </Fragment>
  );
};

ProductGridTwoInfinite.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  shopType: PropTypes.string,
  origin: PropTypes.string,
  productsList: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {

  return {
    type: ownProps.type,
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGridTwoInfinite);
