import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Form, Row, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";
import TextField from '@mui/material/TextField';
import { isMobileOnly } from 'react-device-detect';



function ExportProductsModal({open, setOpen, products, currentQuery}) {
    const [sendEmail, setSendEmail] = useState(true);
    const [email, setEmail] = useState(currentQuery && currentQuery.email && currentQuery.email != "" ? currentQuery.email : null);
    const [name, setName] = useState(currentQuery && currentQuery.name && currentQuery.name != "" ? currentQuery.name : null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [note, setNote] = useState(currentQuery && currentQuery.note && currentQuery.note != "" ? currentQuery.note : null);

    const handleClose = () => {
        setOpen(false)
    }

    const submit = (email, name, products, currentQuery) => {
        setLoading(true)
        setError(false);
        axios.post("https://front-professional-api.therunningcollective.fr/export-products", {
            id: currentQuery.id && currentQuery.id != "" ? currentQuery.id : "",
            email: email,
            products: products,
            name: name,
            userSize: currentQuery && currentQuery.sizes ? currentQuery.sizes : "",
            userMotorPreference: currentQuery && currentQuery.motor ? currentQuery.motor : "",
            userPainType: currentQuery && currentQuery.pains ? currentQuery.pains : "",
            userShoeType: currentQuery && currentQuery.types ? currentQuery.types : "",
            userStrideAnalysis: currentQuery && currentQuery.analysis ? currentQuery.analysis : "",
            note: note
        }, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-pro-client-token')).token}`
            }
            ,}
        ).then((res) => {
            localStorage.removeItem("trc-pro-front-exported");
            window.location.href = "/appointments";
            //window.location.reload()
        }).catch((err) => {
            setError(true);
            console.log(err);
            setLoading(false)
        })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: products && products.length > 1 ? isMobileOnly ? "100%" :  1000 : isMobileOnly ? "100%" : 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        maxHeight: "600px",
        overflow: "auto",
        p: 4,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px", marginBottom: "30px"}}>
                        Récapitulatif de la sélection : 
                    </Typography>
                    {
                        products && products.length > 0 ?
                        <Row>
                            {
                                products.map(product => {
                                    return(
                                        <Col xs={products && products.length > 1 ? isMobileOnly ? 12 : 6 : 12}>
                                            <Row style={{marginBottom: "25px"}}>
                                                <Col xs={4} className={"text-right"}>
                                                    <img src={product.firstImageUrl} style={{maxWidth: "100px"}}/>
                                                </Col>
                                                <Col xs={8} className={"text-left"}>
                                                    <div style={{fontSize: "16px"}}>
                                                        {product.name}
                                                    </div>
                                                    <div style={{color: "gray"}}>
                                                        Poids : {product.weigh ? product.weigh + "g" : "N/A"}
                                                    </div>
                                                    <div style={{color: "gray"}}>
                                                        Drop : {product.drop ? product.drop + "mm" : "N/A"}
                                                    </div>
                                                    <div style={{color: "gray"}}>
                                                        Stack : {product.stack ? product.stack : "N/A"}
                                                    </div>
                                                    <div style={{color: "gray"}}>
                                                        Type d'amorti : {product.cushioningType ? product.cushioningType : "N/A"}
                                                    </div>
                                                    <div style={{color: "gray"}}>
                                                        Type d'utilisation : {product.shopType ? product.shopType.map((type, index) => {
                                                            return(
                                                                <span>{index > 0 ? " - " : ""}{type}</span>
                                                            )
                                                        }) : "N/A"}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>                         
                                    )
                                })
                            }
                        </Row>
                        : null 
                    }

                    {
                        sendEmail ?
                            <div className='container'>
                                <Row style={{marginBottom: "30px"}} className='justify-content-center'>
                                    <Col xs={products && products.length > 1 ? isMobileOnly ? 12 : 5 : 8} className='text-right  align-self-center'>
                                        <div style={{textAlign: "left"}}>Nom/Prénom du patient :</div>
                                        <input
                                            label="nom/prénom du patient"
                                            style={{backgroundColor: "white", border: "1px solid black", borderRadius: "10px", fontSize: "15px"}}
                                            value={name} 
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </Col>  
                                    <Col xs={products && products.length > 1 ? isMobileOnly ? 12 : 5 : 8} className='text-right  align-self-center'>
                                        <div style={{textAlign: "left"}}>E-mail du patient :</div>
                                        <input
                                            label="email du patient"
                                            style={{backgroundColor: "white", border: "1px solid black", borderRadius: "10px", fontSize: "15px"}}
                                            value={email} 
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Col>                         
                                </Row>
                            </div> 
                        : null
                    }

                    <div className='container'>
                        <Row style={{marginBottom: "30px"}} className='justify-content-center'>
                            <Col xs={products && products.length > 1 ? isMobileOnly ? 12 : 5 : 8} className='text-right  align-self-center'>
                            <div style={{textAlign: "left"}}>Note pour le patient : (facultatif)</div>
                            <textarea
                                label="note"
                                rows="3"
                                style={{backgroundColor: "white", border: "1px solid black", borderRadius: "10px", fontSize: "15px"}}
                                value={note} 
                                onChange={e => setNote(e.target.value)}
                                />
                            </Col>                         
                        </Row>
                    </div> 

                    {
                        error ?
                        <Row style={{marginTop: "10px", textAlign:"center"}}>
                            <Col xs={12} style={{color: "red"}}>
                                Une erreur s'est produite, veuillez réessayer.
                            </Col>
                        </Row>
                        : null 
                    }
                    
                    {
                        loading ?
                        <Row style={{marginTop: "10px", textAlign:"center"}}>
                            <Col xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            </Col>
                        </Row>
                        : 
                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                            <Col xs={6}>
                                <button
                                    onClick={() => handleClose()}
                                    style={{
                                        backgroundColor: "rgb(203, 200, 200)",
                                        color: "black",
                                        borderRadius:'5px',
                                        padding: "5px 40px",
                                        border: "none",
                                        fontSize: "15px",
                                        fontWeight: 600
                                    }}>
                                    Annuler
                                </button>
                            </Col>
                            <Col xs={6}>
                                {
                                    sendEmail && (!email || email == "")
                                    ?
                                        <button
                                            disabled
                                            style={{
                                                backgroundColor: "gray",
                                                color: "white",
                                                borderRadius:'5px',
                                                padding: "5px 40px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                            }}>
                                            Envoyer
                                        </button>
                                    : 
                                        <button
                                            onClick={() => submit(email, name, products, currentQuery)}
                                            style={{
                                                backgroundColor: "black",
                                                color: "white",
                                                borderRadius:'5px',
                                                padding: "5px 40px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                            }}
                                            >
                                            Envoyer
                                        </button>
                                }                       
                            </Col>
                        </Row>
                    }
                    

                </Box>
            </Modal>
        </div>
    );
}

ExportProductsModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    forecast: PropTypes.any,
};

export default ExportProductsModal;