import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";
import Countdown from "react-countdown";

const PrivateSalesSingleTwo = ({
                                privateSale,
                                currency,
                                sliderClassName,
                                spaceBottomClass,
                                colorClass,
                                titlePriceClass, isSide, index
                              }) => {

   // Renderer callback with condition
   const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>Se termine dans {days}j {hours}h {minutes}mn</span>;
  };

  const rendererSoon = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>Commence dans {days}j {hours}h {minutes}mn</span>;
  };

  return (
      <Fragment>
        <div
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
            style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}

        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`sales-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="product-img" style={{backgroundColor: "white", width: isMobileOnly ? "120px !important" : "230px !important", textAlign: "center", height: isMobileOnly ? "120px !important" : "230px !important", overflow: "hidden"}}>
              <a href={privateSale.redirectLink} target="_blank">
                <img
                    className="default-img"
                    src={(privateSale.cover != "" ? privateSale.cover : "")}
                    alt={privateSale.name + " au meilleur prix !"}
                />
              </a>
              

              {privateSale.availableSoon ? (
                  <div className="product-img-badges">
                    <span className="purple" style={{backgroundColor : 'red', color : 'white'}}>Bientôt disponible</span>
                  </div>
              ) : (
                  ""
              )}

            </div>
            <div className="product-content-2">
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2">
                  <h3 style={{lineHeight: "13px", fontSize: "16px"}}>
                  <a href={privateSale.redirectLink} target="_blank">
                      {privateSale.name} x {privateSale.merchantName}
                    </a>
                  </h3>
                  <div style={{lineHeight: "15px", marginTop: "5px"}}>
                    <a href={privateSale.redirectLink} target="_blank">
                      <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>{privateSale.description}</span>
                    </a>
                  </div>
                  <div style={{lineHeight: "15px", marginTop: "5px"}}>
                    <a href={privateSale.redirectLink} target="_blank">
                      <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>
                        {
                          privateSale.availableSoon ? 
                          <Countdown date={privateSale.beginDate} renderer={rendererSoon}></Countdown>:
                                                   <Countdown date={privateSale.endDate} renderer={renderer}></Countdown>
                        }
                        </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

PrivateSalesSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  privateSale: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default PrivateSalesSingleTwo;
