import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";

function SnackbarCredits({open, setOpen}) {
    const handleClose = () => {
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                style={{marginBottom: '150px'}}
                open={open}
                autoHideDuration={30000}
                onClose={handleClose}
                message={
                    <div>
                        <div style={{fontSize: "20px", marginBottom: '10px'}}>Comment gagner des TRCoins</div>
                        <div style={{textTransform: "none"}}>Collecter des TRCoins est facile. Plus tu utilises l'application, plus tu en gagnes. Tes TRCoins arriveront 30 jours après l'achat (en période de soldes et Black Friday, les délais peuvent être rallongés) et expireront 365 jours après avoir été gagnés.</div>
                        <div style={{fontSize: "16px", marginTop: '10px', textTransform: "uppercase"}}>Passe une commande</div>
                        <div style={{marginTop: '5px', textTransform: "none"}}>Fais tes achats en utilisant l'application TRC, et gagne 1 TRCoin pour chaque euro dépensé chez nos partenaires (sauf exceptions).</div>
                        <div style={{marginTop: '10px', textTransform: "none"}}>Attention cependant, toute commande annulée ou renvoyée te fera perdre ces précieux TRCoins.</div>
                    </div>
                }
                action={action}
            />
        </div>
    );
}

SnackbarCredits.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default SnackbarCredits;