import React, {useEffect, useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Badge, CardActionArea, CardHeader, Link} from '@mui/material';
import moment from "moment";
import 'moment/locale/fr';
import {GiPathDistance} from 'react-icons/gi'
import {FaLongArrowAltRight} from 'react-icons/fa'
import {slugify} from "../../helpers/product";
import {isMobileOnly} from 'react-device-detect';
import StarIcon from '@mui/icons-material/Star';

export default function RaceCard({race}) {
    const [date, setDate] = useState('')
    const [events, setEvents] = useState([])

    useEffect(() => {
        if(race.beginDate != race.endDate){
            moment.locale("fr");
            var beginDay = new Date(race.beginDate).getDate()
            var beginDateFormat = moment(race.beginDate);
            var beginDayStr = beginDateFormat.locale("fr").format('dddd').slice(0,3)
            var beginMonthStr = beginDateFormat.locale("fr").format('MMM')
            var beginYearStr = beginDateFormat.year()

            var endDay = new Date(race.endDate).getDate()
            var endDateFormat = moment(race.endDate);
            var endDayStr = endDateFormat.locale("fr").format('dddd').slice(0,3)
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            var endYearStr = endDateFormat.year()

            if(endYearStr == beginYearStr){
                if (beginMonthStr != endMonthStr){
                    setDate(beginDayStr + " " + beginDay + " "+ beginMonthStr + " au " + endDayStr + " " + endDay + " " + endMonthStr + " "+ endDateFormat.year())
                } else {
                    setDate(beginDayStr + " " + beginDay + " au " + endDayStr + " " + endDay + " " + endMonthStr + " "+ endDateFormat.year())
                }
            } else {
                if (beginMonthStr != endMonthStr){
                    setDate(beginDayStr + " " + beginDay + " " + beginMonthStr + " " + beginYearStr + " au " + endDayStr + " " + endDay + " " + endMonthStr + " "+ endDateFormat.year())
                } else {
                    setDate(beginDayStr + " " + beginDay + " au " + endDayStr + " " + endDay + " " + endMonthStr + " "+ endDateFormat.year())
                }
            }

        } else {
            var beginDay = new Date(race.beginDate).getDate()
            var beginDateFormat = moment(race.beginDate);
            var beginDayStr = beginDateFormat.locale("fr").format('dddd').slice(0, 3)
            var endDateFormat = moment(race.endDate);
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            setDate(beginDayStr + " " + beginDay + " " + endMonthStr + " " + endDateFormat.year())
        }
        if (race.events && race.events.length > 0){
            var events = []
            race.events.map((event) => {
                let distance
                if (event.distance == ""){
                    distance = event.label
                } else {
                    distance = event.distance
                }
                if(race.type == "athletisme"){
                    events.push(event.label)
                } else {
                    if(distance != ""){
                        if(distance == "J10_13" || distance == "J8_11" || distance == "J6_9" || distance == "J12_19"){
                            distance = "Jeunes"
                        }
                        events.push(distance)
                    }
                }

            })
            let uniqueChars = [...new Set(events)];
            uniqueChars = uniqueChars.sort()
            setEvents(uniqueChars)
        }
    }, [race])
    return (
        <a href={process.env.PUBLIC_URL + "/race/" + (race.shortURL !== "" ? race.shortURL : slugify(race.name))}>
            <Card sx={{ maxWidth: 345 }} style={{borderRadius: "5px", textAlign: "center", boxShadow: "none",
                border: race.isSponsored ? "2px solid gold" : "none"}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height={!isMobileOnly ? "140": "100"}
                        image={race.image && race.image != "" ? race.image :
                            race.type == "trail" ? "https://blog.therunningcollective.fr/wp-content/uploads/2023/05/trail-running.jpeg" :
                                race.type == "triathlon" ? "https://img.therunningcollective.fr/triathlonjeunes-5-jpeg" :
                                    race.type == "athletisme" ? "https://blog.therunningcollective.fr/wp-content/uploads/2023/05/sportcentrum-in-Oordegem-luchtfoto-Persregio-Dender.jpeg"
                                        : "https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"
                        }
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"
                        }}
                        style={{filter: "brightness(70%)"}}
                        alt={race.name}
                    />
                    <div style={styles.overlay}>
                        {race.type ? race.type.toUpperCase() : null}
                    </div>
                    {
                        race.isSponsored ?
                            <div className="product-img-badges" style={styles.overlayFavorite}>
                                <span className="purple" style={{background : 'none'}}><StarIcon style={{color: "gold"}}/></span>
                            </div>
                            : null
                    }
                    <CardContent style={{backgroundColor:"#f6f6f8", padding: !isMobileOnly ? "15px": "5px"}} >

                        <Typography gutterBottom variant="p" component="div" style={{marginBottom: "0px"}}>
                            {race.name.length > 50 ?
                                <p style={{fontWeight: !isMobileOnly ? 500: 700, fontSize: !isMobileOnly ? "14px": "10px"}}>
                                    {`${race.name.toUpperCase().substring(0, 50)}...`}</p>
                                :
                                <p style={{fontWeight: !isMobileOnly ? 500: 700, fontSize: !isMobileOnly ? "14px": "10px"}}>{race.name.toUpperCase()}<br style={{display:"block"}}/></p>
                            }
                        </Typography>
                        <Typography gutterBottom variant="p" component="div" style={{marginBottom: "0px"}}>
                            <p style={{fontSize: !isMobileOnly ? "14px": "10px"}}>{date}</p>
                        </Typography>
                        <hr style={{backgroundColor: "black",
                            marginRight:"30px",
                            marginLeft:"30px",
                            marginBottom: !isMobileOnly ? "10px": "7px",
                            marginTop: !isMobileOnly ? "10px": "7px"}}/>
                        <Typography gutterBottom variant="p" component="div">
                            <p style={{fontSize: !isMobileOnly ? "14px": "10px"}}>
                                {
                                    race.country ?
                                        <img
                                            style={{borderRadius:"10px", marginBottom: "3px", maxHeight: !isMobileOnly ? "100%": "9px"}}
                                            src={race.country ? getFlag(race.country) : null}
                                        />
                                        : null
                                } &nbsp;
                                {race.country ? race.country : ""} - {race.town ? race.town : ""} {race.departmentNumber ? "("+race.departmentNumber+")" : ""}</p>
                        </Typography>
                        <Typography gutterBottom variant="p" component="div">
                            {
                                events && events.length > 0 ?
                                    <p style={{fontSize: !isMobileOnly ? "14px": "10px", marginBottom: !isMobileOnly ? "5px": "0"}}>
                                        <GiPathDistance/>
                                        &nbsp;
                                        {
                                            events.map((event, index) => {
                                                if(event != "-1" && event != ""){
                                                    if (index == (events.length-1)){
                                                        return(
                                                            event
                                                        )
                                                    } else {
                                                        return(
                                                            event + ", "
                                                        )
                                                    }

                                                }
                                            })} </p> : null
                            }
                        </Typography>
                        <Typography gutterBottom variant="p" component="div">
                            <p style={{fontWeight: 500, fontSize: !isMobileOnly ? "14px": "10px"}}>
                                <FaLongArrowAltRight/> Voir en détail
                            </p>
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </a>
    );
}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: '0px',
        left: '20px',
        color: 'white',
        backgroundColor: 'grey',
        padding: "0 10px 5px 10px",
        borderRadius:"0 0 50% 50%",
        textTransform: "uppercase",
        fontSize: !isMobileOnly ? "14px": "10px"
    },
    overlayFavorite: {
        position: 'absolute',
        top: '5px',
        right: '10px',
        fontSize: !isMobileOnly ? "14px": "10px"
    }
}

function getFlag(country) {
    switch (country) {
        case "FRANCE":
            return "https://flagcdn.com/w20/fr.png"
        case "ITALIE":
            return "https://flagcdn.com/w20/it.png"
        case "PORTUGAL":
            return "https://flagcdn.com/w20/pt.png"
        case "CROATIE":
            return "https://flagcdn.com/w20/hr.png"
        case "BOSNIE-HERZEGOVINE":
            return "https://flagcdn.com/w20/ba.png"
        case "POLOGNE":
            return "https://flagcdn.com/w20/pl.png"
        case "ROYAUME-UNI":
            return "https://flagcdn.com/w20/gb-eng.png"
        case "SUEDE":
            return "https://flagcdn.com/w20/se.png"
        case "MACEDOINE DU NORD":
            return "https://flagcdn.com/w20/mk.png"
        case "NORVEGE" :
            return "https://flagcdn.com/w20/no.png"
        case "ETATS-UNIS":
            return "https://flagcdn.com/w20/us.png"
        case "AUTRICHE":
            return "https://flagcdn.com/w20/at.png"
        case "ISLANDE":
            return "https://flagcdn.com/w20/is.png"
        case "ROUMANIE":
            return "https://flagcdn.com/w20/ro.png"
        case "ALLEMAGNE":
            return "https://flagcdn.com/w20/de.png"
        case "SLOVENIE":
            return "https://flagcdn.com/w20/si.png"
        case "SUISSE":
            return "https://flagcdn.com/w20/ch.png"
        case "CANADA":
            return "https://flagcdn.com/w20/ca.png"
        case "AFRIQUE DU SUD":
            return "https://flagcdn.com/w20/za.png"
        case "IRLANDE":
            return "https://flagcdn.com/w20/ie.png"
        case "MEXIQUE":
            return "https://flagcdn.com/w20/mx.png"
        case  "GEORGIE":
            return "https://flagcdn.com/w20/ge.png"
        case  "ESPAGNE":
            return "https://flagcdn.com/w20/es.png"
        case "BULGARIE":
            return "https://flagcdn.com/w20/bg.png"
        case "NOUVELLE-ZELANDE":
            return "https://flagcdn.com/w20/nz.png"
        case "ANDORRE":
            return "https://flagcdn.com/w20/ad.png"
        case "GRECE":
            return "https://flagcdn.com/w20/gr.png"
        case "KENYA":
            return "https://flagcdn.com/w20/ke.png"
        case "COLOMBIE":
            return "https://flagcdn.com/w20/co.png"
        case "BELGIQUE":
            return "https://flagcdn.com/w20/be.png"
        case "INDONESIE":
            return "https://flagcdn.com/w20/id.png"
        case "DANEMARK":
            return "https://flagcdn.com/w20/dk.png"
        case "INDE":
            return "https://flagcdn.com/w20/in.png"
        case "AUSTRALIE":
            return "https://flagcdn.com/w20/au.png"
        case "TURQUIE":
            return "https://flagcdn.com/w20/tr.png"
        case "FINLANDE":
            return "https://flagcdn.com/w20/fi.png"
        case "TCHEQUIE":
            return "https://flagcdn.com/w20/cz.png"
        case "PEROU":
            return "https://flagcdn.com/w20/pe.png"
        case "REPUBLIQUE DOMINICAINE":
            return "https://flagcdn.com/w20/do.png"
        case "ZIMBABWE":
            return "https://flagcdn.com/w20/zw.png"
        case "ARGENTINE":
            return "https://flagcdn.com/w20/ar.png"
        case "JAPON":
            return "https://flagcdn.com/w20/jp.png"
        case "HONGRIE":
            return "https://flagcdn.com/w20/hu.png"
        case "MAURICE":
            return "https://flagcdn.com/w20/mu.png"
        case "SLOVAQUIE":
            return "https://flagcdn.com/w20/sk.png"
        case "EQUATEUR":
            return "https://flagcdn.com/w20/ec.png"
        case "VIETNAM":
            return "https://flagcdn.com/w20/vn.png"
        case "GUATEMALA":
            return "https://flagcdn.com/w20/gt.png"
        case "MONGOLIE":
            return "https://flagcdn.com/w20/mn.png"
        case "JERSEY":
            return "https://flagcdn.com/w20/je.png"
        case "ESTONIE":
            return "https://flagcdn.com/w20/ee.png"
        case "CAMBODGE":
            return "https://flagcdn.com/w20/kh.png"
        case "MONTENEGRO":
            return "https://flagcdn.com/w20/me.png"
        case "CAP-VERT":
            return "https://flagcdn.com/w20/cv.png"
        case "COREE DU SUD":
            return "https://flagcdn.com/w20/kr.png"
        case "PARAGUAY":
            return "https://flagcdn.com/w20/py.png"
        case "BRESIL":
            return "https://flagcdn.com/w20/br.png"
        case "VENEZUELA":
            return "https://flagcdn.com/w20/ve.png"
        case "COSTA RICA":
            return "https://flagcdn.com/w20/cr.png"
        case "NOUVELLE-CALEDONIE":
            return "https://flagcdn.com/w20/nc.png"
        case "JORDANIE":
            return "https://flagcdn.com/w20/jo.png"
        case "CHINE":
            return "https://flagcdn.com/w20/cn.png"
        case "CHYPRE":
            return "https://flagcdn.com/w20/cy.png"
        case "LUXEMBOURG":
            return "https://flagcdn.com/w20/lu.png"
        case "RUSSIE":
            return "https://flagcdn.com/w20/ru.png"
        case "BOLIVIE":
            return "https://flagcdn.com/w20/bo.png"
        case "MAROC":
            return "https://flagcdn.com/w20/ma.png"
        case "CHILI":
            return "https://flagcdn.com/w20/cl.png"
        case "LETTONIE":
            return "https://flagcdn.com/w20/lv.png"
        case "PAYS-BAS":
            return "https://flagcdn.com/w20/nl.png"
        default:
            console.log(country)
            return ""
    }
}