import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {BrowserView, isTablet, isMobileOnly} from 'react-device-detect';
import {getProfileName} from "../../wrappers/header/HeaderTwo";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";

const Logo = ({logoClass, location, offer, isPortrait }) => {
    const { t } = useTranslation();

    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
        <Row style={{marginLeft: "15px"}}>
          <Col xs={2} style={{marginLeft: "-5px"}}>
              <img style={{height: isMobileOnly ? "35px" : '40px'}} src="../../assets/img/noune/logo-trc.png" alt="The Running Collective"/>
          </Col>
          <Col xs={10} style={{marginLeft: isMobileOnly ? "5px" : "-3%"}}>
             <div style={{textTransform: "uppercase", textAlign: "left", fontWeight: '500', paddingTop: '10px', fontSize: isMobileOnly ? '18px' : '23px', color: '#000', letterSpacing: '1px'}}>
                The Running Collective
             </div>
          </Col>
          <BrowserView>
              {!isMobileOnly ?
                  <div style={{fontSize: '12px', color: '#000', letterSpacing: '1px', marginLeft: "20px"}}>
                      {t('descriptionhead')}
                  </div>
                  : null }
          </BrowserView>
    </Row>
        </Link>

    );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
  location: PropTypes.any,
    isPortrait:PropTypes.any,
};

export default Logo;
