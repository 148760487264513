import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteFromCart } from "../../redux/actions/cartActions";
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, GetUserData} from "../../App";
import {useTranslation} from "react-i18next";
import { TiExportOutline } from "react-icons/ti";
import { AiOutlineExport } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import { MdFavoriteBorder } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";

const IconGroup = ({
  iconWhiteClass,
  favLength
}) => {
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState(null);
  const [productSearch, setProductSearch] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [slugProductResearched, setSlugProductResearched] = useState(null);
  const { i18n, t } = useTranslation();
  const [origin, setOrigin] = useState("fr")

  useEffect(() => {
    setOrigin(i18n.language)
  }, [])

  useEffect(() => {
    if (products && productSearch) {
      setFilteredProducts(filterList(productSearch, products))
    }
  }, [productSearch, products])


  useEffect(() => {
    let token = JSON.parse(localStorage.getItem('trc-pro-client-token'))
    if (token) {
      setUser(token)
    }
  }, []);

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
      style={{marginTop: "10px"}}
    >
      <div className="same-style account-setting d-none d-lg-block" style={{width: "100%"}}>
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" style={{fontSize: "32px"}}/>
          </button>
        <div className="account-dropdown">
            {user ?
                <ul>
                  <li>
                    <span style={{wordWrap: 'break-word'}}>{user.firstName}&nbsp;{user.name}</span>
                    <hr/>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/my-account"}>
                      {t('myaccount')}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/appointments"}>
                      Mon historique
                    </Link>
                  </li>               
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/logout"}>
                      {t('logout')}
                    </Link>
                  </li>
                </ul>

                :
                  <ul>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/login-register"}>{t('login')}</Link>
                    </li>
                  </ul>

                  }
        </div>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none" style={{marginRight: "20px", marginLeft: "0"}}>
        <>
          <div
            style={{cursor: "pointer", position: "absolute"}}
            className="account-setting-active"
            onClick={() => window.location.href = "/favorites"}
          >
            <GrFavorite size={"1.6rem"}/>
          </div>
          {
            favLength ?
            <div 
              style={{position: "absolute", fontSize: "10px", backgroundColor: "red", width: "15px", height: "15px", color: "white", borderRadius: "50%", right: "-30px", top : "-4px"}}
            >
              <div style={{marginTop: "-4px", marginLeft: "5px"}}>
              {favLength}
              </div>
            </div>
            : null
          }                          
        </>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

export function filterList(productSearch, initialProducts){
  const excludeColumns = ["id", "colors", "posts", "sizes", "retailers"];
  const lowercasedValue = productSearch.toLowerCase().trim();

  const filteredData = initialProducts.filter(item => {
    return Object.keys(item).some(key =>
      excludeColumns.includes(key) ? false : item[key] ? item[key].toString().toLowerCase().includes(lowercasedValue) : false
    );
  });
  return filteredData
}

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
